import md5 from 'md5';
import React, { useGlobal, useState, useRef } from 'reactn';
import Header from './../Header/Header.js';
import useQueryParam from './../../hooks/useQueryParam';
import QuestionForm from './../QuestionForm/QuestionForm';
import Signet from './../../images/signet.png';
import Keyvisual from './../../images/Keyvisual2024-light.svg';

const access_token = '31eb964167da238c090f815f238d8076';

function Livestream(props) {
	const [query, setQuery] = useQueryParam('password', '');
	const [isAuthenticated, setIsAuthenticated] = useGlobal('isAuthenticated');
	const [passwordValid, setPasswordValid] = useState('');
	const initialPassword = query;
	const [password, setPassword] = useState(initialPassword);
	const passwordInput = useRef(null);

	const handleSubmit = (event) => {
		event.preventDefault();
		if (md5(event.target.value) === access_token) {
			setPasswordValid('is-valid');
			setTimeout(() => {
				setIsAuthenticated(1);
			}, 2000);
		} else {
			setPasswordValid('is-invalid');
		}
	};
	if (password === access_token) {
		setIsAuthenticated(1);
	}

	return (
		<>
			<Header />
			<div
				className={
					isAuthenticated
						? 'container-fluid bg-main py-3 py-lg-5 px-lg-5'
						: 'container bg-main py-lg-5 py-3 px-lg-5'
				}>
				<div className={isAuthenticated ? 'row justify-content-center' : 'row'}>
					<div
						className={
							isAuthenticated ? 'col-12 col-lg-9 mb-3' : 'col-12 mb-3'
						}>
						<h1>Herzlich willkommen zu unserem Jahresauftakt 2024!</h1>
					</div>
				</div>
				{isAuthenticated ? (
					<>
						<img
							className='keyvisual'
							src={Keyvisual}
							alt='Jahresauftakt 2023'
						/>
						<div className='row justify-content-center'>
							<div className='col-12 col-lg-9'>
								<div className='embed-responsive embed-responsive-16by9'>
									<iframe
										width='845'
										height='475'
										className='embed-responsive-item videoiframe'
										allowFullScreen
										src='https://start.video-stream-hosting.de/player.html?serverapp=ideenplantage-live&streamname=bvb2024.smil&nocookie=1'></iframe>
								</div>
							</div>
						</div>
						<div className='row justify-content-center'>
							<div className='col-12 col-lg-9 mt-4 '>
								<QuestionForm />
							</div>
						</div>
					</>
				) : (
					<>
						<p class='lead'>Hier geht's los...</p>
						{/* <p>
							Am 20. Januar findet unser bankweiter Jahresauftakt statt. Wieder
							treffen wir uns virtuell, denn so können alle Mitarbeiter:innen
							und Führungskräfte gemeinsam dabei sein. Das ist in diesem Jahr
							besonders wichtig, denn die Dynamik wird in 2023 nicht nachlassen.
							Um damit gut umzugehen und die Herausforderungen auch mit
							Begeisterung anzupacken, braucht es die gebündelte Kraft von uns
							allen.
						</p>
						<p>
							In den kommenden 12 Monaten werden viele Themen unser Geschäft
							prägen. Wir agieren in einem veränderten Zinsumfeld, die
							Energie(un)sicherheit beschäftigt viele unserer Kunden und das
							Thema Nachhaltigkeit rückt in den Vordergrund, um nur einige
							Beispiele zu nennen. Nicht zu vergessen, dass wir endlich ins QVB
							umziehen werden.
						</p>
						<p>
							Seien Sie deshalb am 20. Januar dabei und melden Sie sich mit
							Ihren Zugangsdaten ganz einfach an.
						</p>
						<p>Wir freuen uns auf Sie!</p>
						<p>Herzliche Grüße</p>
						<div>
							<img src={Signet} alt='Signet' className='img-fluid' />
						</div> */}

						<form onSubmit={(e) => handleSubmit(e)}>
							<div className='form-row justify-content-center'>
								<div className='col-12 col-md-6 my-5'>
									<input
										type='password'
										placeholder='Ihr Passwort'
										className={'form-control form-control-lg ' + passwordValid}
										onKeyPress={(e) => {
											e.key === 'Enter' && e.preventDefault();
										}}
										ref={passwordInput}
										onChange={(e) => handleSubmit(e)}
										onFocus={(e) => handleSubmit(e)}
									/>
								</div>
							</div>
						</form>
					</>
				)}
			</div>
		</>
	);
}

export default Livestream;
