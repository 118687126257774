import React, { setGlobal } from 'reactn';
import Footer from './components/Footer/Footer';
import Livestream from './components/Livestream/Livestream';

import './App.css';

function App() {
	setGlobal({ isAuthenticated: 0 });
	//const [isAuthenticated, setIsAuthenticated] = useGlobal("isAuthenticated");

	return (
		<div className='App'>
			{true && (
				<>
					<Livestream />
					<Footer />
				</>
			)}
		</div>
	);
}

export default App;
