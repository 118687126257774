import React, { useState } from 'reactn';
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';
const application_auth = {
	user: 'API',
	password: 'GLcM DkXy V5AM GLNz 65Kb wXwE',
};

const QuestionForm = () => {
	const [formState, setFormState] = useState('formState');
	const [category, setCategory] = useState(null);
	const [showCategory, setShowCategory] = useState(null);

	const successMessage = (
		<div className='alert alert-success' role='alert'>
			Ihre Frage wurde erfolgreich eingereicht.
		</div>
	);

	const errorMessage = (
		<div className='alert alert-danger' role='alert'>
			Die Übermittlung ist schief gegangen. Haben Sie eine Frage eigegeben und
			eine Kategorie gewählt?
		</div>
	);

	const handleClick = (event) => {
		setShowCategory(event.target.innerHTML);
		setCategory(event.target.dataset.key);
		console.log(event);
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		if (event.target.question.value && category) {
			axios
				.post(
					'https://cms.bvb-auftakt.de/wp-json/wp/v2/questions',
					{
						title: event.target.question.value || 'Leer',
						excerpt: event.target.name.value || 'Anonym',
						status: 'draft',
						acf: {
							category: category,
						},
					},
					{
						auth: {
							username: application_auth.user,
							password: application_auth.password,
						},
					}
				)
				.then(function (response) {
					setFormState('success');
					setCategory(null);
					setShowCategory(null);
					event.target.reset();
					setInterval(() => setFormState('formState'), 8000);
				})
				.catch(function (error) {
					setFormState('error');
				});
		} else {
			setFormState('error');
		}
	};

	return (
		<>
			{formState === 'success' && <>{successMessage}</>}
			{formState === 'error' && <>{errorMessage}</>}
			<form onSubmit={(e) => handleSubmit(e)}>
				<div className='form-row'>
					<div className='col mb-3'>
						<textarea
							className='form-control'
							placeholder='Bitte teilen Sie uns Ihr Feedback, Ihre Hinweise und Ihre Fragen bzw. Vorschläge mit. Bitte wählen Sie vorab den Bereich aus, in dem Sie tätig sind. Dies erleichtert uns die Rückmeldung an Sie und Ihr Team.'
							rows='4'
							name='question'
							id='question'></textarea>
					</div>
				</div>
				{/* dropdown menu row */}
				<div className='form-row'>
					<div className='col mb-3'>
						<Dropdown aria-required>
							<Dropdown.Toggle
								variant='secondary'
								id='dropdown-basic'
								size='lg'>
								{showCategory
									? showCategory
									: 'Ich arbeite in folgendem Bereich:'}
							</Dropdown.Toggle>

							<Dropdown.Menu className='m-0'>
								<Dropdown.Item
									data-key='Beteiligungsmanagement'
									onClick={(e) => handleClick(e)}>
									Beteiligungsmanagement
								</Dropdown.Item>
								<Dropdown.Item
									data-key='Betriebsorganisation'
									onClick={(e) => handleClick(e)}>
									Betriebsorganisation
								</Dropdown.Item>
								<Dropdown.Item
									data-key='Compliance'
									onClick={(e) => handleClick(e)}>
									Compliance
								</Dropdown.Item>
								<Dropdown.Item
									data-key='Finanzen'
									onClick={(e) => handleClick(e)}>
									Finanzen
								</Dropdown.Item>
								<Dropdown.Item
									data-key='Firmenkunden'
									onClick={(e) => handleClick(e)}>
									Firmenkunden
								</Dropdown.Item>
								<Dropdown.Item
									data-key='Geld- und Kapitalmarkt'
									onClick={(e) => handleClick(e)}>
									Geld- und Kapitalmarkt
								</Dropdown.Item>
								<Dropdown.Item
									data-key='Human Relations'
									onClick={(e) => handleClick(e)}>
									Human Relations
								</Dropdown.Item>
								<Dropdown.Item
									data-key='Immobilien & Erneuerbare Energien'
									onClick={(e) => handleClick(e)}>
									Immobilien & Erneuerbare Energien
								</Dropdown.Item>
								<Dropdown.Item
									data-key='Interne Revision'
									onClick={(e) => handleClick(e)}>
									Interne Revision
								</Dropdown.Item>
								<Dropdown.Item
									data-key='Just Banking'
									onClick={(e) => handleClick(e)}>
									Just Banking
								</Dropdown.Item>
								<Dropdown.Item
									data-key='Kredit'
									onClick={(e) => handleClick(e)}>
									Kredit
								</Dropdown.Item>
								<Dropdown.Item
									data-key='Marke und Kommunikation'
									onClick={(e) => handleClick(e)}>
									Marke und Kommunikation
								</Dropdown.Item>
								<Dropdown.Item
									data-key='Private Banking'
									onClick={(e) => handleClick(e)}>
									Private Banking
								</Dropdown.Item>
								<Dropdown.Item
									data-key='Private Kunden'
									onClick={(e) => handleClick(e)}>
									Private Kunden
								</Dropdown.Item>
								<Dropdown.Item
									data-key='Strategische Geschäftsfeldsteuerung'
									onClick={(e) => handleClick(e)}>
									Strategische Geschäftsfeldsteuerung
								</Dropdown.Item>
								<Dropdown.Item
									data-key='Vertriebsmanagement'
									onClick={(e) => handleClick(e)}>
									Vertriebsmanagement
								</Dropdown.Item>
								<Dropdown.Item
									data-key='Vertriebsservice'
									onClick={(e) => handleClick(e)}>
									Vertriebsservice
								</Dropdown.Item>
								<Dropdown.Item
									data-key='Vorstandsstab/Recht'
									onClick={(e) => handleClick(e)}>
									Vorstandsstab/Recht
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>
				{/* end dropdown menu row */}
				<div className='form-row'>
					<div className='col mb-3'>
						<button type='submit' className='btn btn-primary btn-lg mb-2'>
							Frage absenden
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export default QuestionForm;
