import React, { useGlobal } from 'reactn';
import Logo from './../../images/BVB_desktop_logo.svg';
import Claim from './../../images/Claim2024.svg';
import Keyvisual from './../../images/Keyvisual2024.svg';

const Header = () => {
	const [isAuthenticated, setIsAuthenticated] = useGlobal('isAuthenticated');

	return (
		<>
			<div
				className={
					isAuthenticated ? 'container-fluid px-0 bg-white' : 'container px-0'
				}>
				{isAuthenticated ? (
					<nav className='navbar navbar-expand-lg navbar-light p-3 justify-content-center'>
						<div className='col-12'>
							<div
								className={
									isAuthenticated
										? 'row justify-content-between align-items-center'
										: 'row'
								}>
								<div className='col pl-lg-4'>
									<a to='/' className='navbar-brand'>
										<img
											src={Logo}
											alt='Berliner Volksbank Logo'
											className='logo'
										/>
									</a>
								</div>
								<div className='col pl-lg-4 text-right'>

										<img
											src={Claim}
											alt='Berliner Volksbank Logo'
											className='logo'
										/>

								</div>
							</div>
						</div>
					</nav>
				) : (
					<></>
				)}
				{isAuthenticated === 0 && (
					<div className='col-12 px-0 keyvisual_wrapper'>
						<>
							<img
								src={Logo}
								alt='Berliner Volksbank Logo'
								className='bvb-logo'
							/>

							<img
								src={Claim}
								alt='Berliner Volksbank Logo'
								className='bvb-claim'
							/>

							<img
								className='keyvisual'
								src={Keyvisual}
								alt='Jahresauftakt 2024'
							/>
						</>
					</div>
				)}
			</div>
		</>
	);
};

export default Header;
