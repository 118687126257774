import React, { useGlobal } from 'reactn'

const Footer = () => {
    const [isAuthenticated, setIsAuthenticated] = useGlobal('isAuthenticated')
  const container = isAuthenticated ? 'container-fluid' : 'container'
  return(
  <div className={isAuthenticated ? "o-footer container-fluid px-0" : "o-footer container px-0"}>
  <nav className="navbar navbar-expand-lg navbar-dark justify-content-end text-right">
    					<div className="col-12">
						<div
							className={isAuthenticated ? "row justify-content-center" : "row"}
						>
							<div className={isAuthenticated ? "col-12 col-lg-8" : "col-12"}>
                <div className="row justify-content-end">


    <ul className="navbar-nav col-auto">
      <li className="nav-item">
        <a className="nav-link" target="_blank" href="https://www.berliner-volksbank.de/service/impressum.html">Impressum</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" target="_blank" href="https://www.berliner-volksbank.de/service/datenschutzhinweis-zur-website.html">Datenschutz</a>
      </li>
    </ul>
                </div>
    							</div>
						</div>
					</div>

</nav></div>
)}

export default Footer;
